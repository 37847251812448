<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link
          class="text-decoration--none"
          :to="{ name: 'business-areas' }"
        >
          Business Areas
        </router-link>
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4" v-if="form">
      <div class="display-2 font-weight-bold my-6 text-capitalize">
        {{ displayName }}
      </div>

      <div class="d-flex my-6">
        <v-form ref="form" v-if="form" style="max-width: 400px">
          <label class="text-field-label">Name</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            v-model="form.business_area_name"
            :error-messages="form.$getError('business_area_name')"
          />

          <v-checkbox
            required
            class="mt-2"
            label="Approved"
            v-model="form.is_approved"
            :error-messages="form.$getError('is_approved')"
          />

          <div class="mt-4">
            <v-btn
              color="primary"
              depressed
              class="mr-4 px-6"
              height="40px"
              @click="updateBusinessAreaDetails"
              :loading="form.$busy"
            >
              Update
            </v-btn>

            <v-btn
              color="red"
              dark
              depressed
              class="mr-4 px-6"
              height="40px"
              @click="deleteDialog = true"
              :loading="form.$busy"
            >
              Delete
            </v-btn>
          </div>
        </v-form>

        <v-dialog v-model="deleteDialog" max-width="290">
          <v-card>
            <v-card-title class="headline">Delete ?</v-card-title>

            <v-card-text>
              Are you sure you want to delete this business area?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text @click="deleteDialog = false" :loading="form.$busy">
                Cancel
              </v-btn>

              <v-btn
                color="red"
                text
                @click="deleteBusinessArea"
                :loading="form.$busy"
              >
                Yes, Delete please
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'

import Form from '@/utils/form'

export default {
  name: 'BusinessAreaDetails',

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
      },
      loading: false,
      deleteDialog: false,
      form: null,
      tabItems: [{ tab: 'Info' }],
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
    }
  },

  computed: {
    ...mapState({
      businessArea: (state) => state.businessArea.businessAreaDetails,
      authUser: (state) => state.auth.user,
    }),
    displayName() {
      return this.form.business_area_name
    },
  },

  methods: {
    ...mapActions({
      getBusinessAreaDetails: 'businessArea/getBusinessAreaDetails',
      updateBusinessArea: 'businessArea/updateBusinessArea',
    }),

    ...mapMutations({
      clearBusinessAreaDetails: 'businessArea/clearBusinessAreaDetails',
    }),

    async getBusinessArea() {
      this.loading = true
      await this.getBusinessAreaDetails(this.$route.params.id)
      this.form = new Form(this.businessArea)
      this.loading = false
    },

    async updateBusinessAreaDetails() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.updateBusinessArea(this.form.$data())
        .then(() => {
          this.form.$busy = false
          this.showSnackbar(
            'Business area details successfully updated!',
            'success'
          )
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    deleteBusinessArea() {
      this.form.$busy = true
      this.businessArea.delete().then(() => {
        this.form.$busy = false
        this.deleteDialog = false
        this.showSnackbar('Business area deleted successfully!')
        this.$router.replace('/business-areas')
      })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
  },

  created() {
    this.getBusinessArea()
  },

  destroyed() {
    this.clearBusinessAreaDetails()
  },

  watch: {
    $route() {
      this.getBusinessArea()
    },
  },
}
</script>
